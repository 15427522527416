import { useEffect } from "react";
import { navigate } from "gatsby";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "fr";
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "fr";

  switch (lang) {
    default:
      return "fr";
  }
};

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();
    navigate(`/${urlLang}/`);
  }, []);

  return null;
};

export default IndexPage;